import { ReactElement, useMemo } from 'react';
import useOrderPrice, { PassPrice } from '../../hooks/useOrderPrice';
import PassInfo from '../../types/passInfo';
import PassSummary from './passSummary';
import style from './passSummaryCollection.module.css';

type PassSummaryCollectionProps = {
  showVehicleInfo: boolean;
  showEditBtn: boolean;
  showTotal: boolean;
  data: Array<PassInfo>;
  editLink?: string;
  // NOTE: DO NOT USE FOR ANY OTHER PURPOSE THAN RECEIPT PAGES/ORDER SUMMARY PAGES
  countPaidPasses?: boolean;
};

export const PassSummaryCollection = (
  props: PassSummaryCollectionProps,
): ReactElement => {
  const { data, showTotal, editLink, countPaidPasses } = props;

  const { totalPrice, passes } = useOrderPrice(data, {
    countPaidPasses,
  });
  const passPriceInfo = useMemo(() => {
    const res = {} as Record<string, PassPrice>;
    passes?.forEach(p => {
      res[p.passInfoId] = p;
    });
    return res;
  }, [passes]);

  return (
    <div className={style.infoBox}>
      {data.map((elem: PassInfo, idx: number) => (
        <div
          className={style.summaryBox}
          key={idx + '-pass-summary-' + elem.passInfoId + elem.name}
        >
          <PassSummary
            data={elem}
            // allPasses={data}
            idx={idx}
            showVehicleInfo={props.showVehicleInfo}
            showEditBtn={props.showEditBtn}
            editLink={editLink}
            priceInfo={passPriceInfo[elem.passInfoId]}
            passes={passes}
          />
        </div>
      ))}
      {showTotal && (
        <div className={style.totalPriceBox}>
          <span className={style.totalPriceTitle}>Total</span>
          <span className={style.totalPriceValue}>${totalPrice}</span>
        </div>
      )}
    </div>
  );
};
PassSummaryCollection.defaultProps = {
  editLink: '',
};

export default PassSummaryCollection;

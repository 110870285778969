import React, { ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../../assets/backArrow.svg';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import { FamilyAndFriendsPassCollection } from '../../../components/familyAndFriendsPassCollection/familyAndFriendsPassCollection';
import style from './familyAndFriendsPass.module.css';
import useFamilyAndFriendsPass from './usefamilyAndFriendsPass';

export default function FamilyAndFriendsPass(): ReactElement {
  const history = useHistory();
  const { alert, alertColor, doShare, successRedirect, disable } = useFamilyAndFriendsPass();

  if (successRedirect) {
    // redirect to my passes page
    history.replace('/resident/my-passes');
  }

  return (
    <div className={style.container}>
      <div className={style.box}>
        <div className={`${style.box__subHeader}`}>
          <Link to="/resident/get-passes" className={style.arrow_left}>
            <BackArrow />
          </Link>
          <h1 className={`${style.box__title}  `}>Share a Pass</h1>
        </div>
        {alert ? (
          <div className={style.alertBox}>
            <GenericAlert title={alert} color={alertColor} hidden={!alert} />
          </div>
        ) : null}
        <div className={`${style.box__flexBox} white `}>
          <div className={style.passForm}>
            <FamilyAndFriendsPassCollection max={100} min={1} submitHandler={doShare} disable={disable} />
          </div>
        </div>
      </div>
    </div>
  );
}

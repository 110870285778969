import { gql, useQuery } from '@apollo/client';
// import html2canvas from 'html2canvas';
import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import LoaderCircle from '../../../common_lib_front/components/loaderCircle/loaderCircle';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
// import { rentalUnitInfo } from '../../../common_lib_front/common_lib_front/types/rentalUnitInfo';
// import HostPassSummary from '../../../common_lib_front/components/hostPassSummary/hostPassSummary';
// import RentalPass from '../../../common_lib_front/components/rentalPass/rentalPass';
import { formatDate } from '../../../common_lib_front/utilities/formatDate';
import RegistrationProcessNav from '../../../components/registrationProcessNav/registrationProcessNav';
import style from './registrationThankYou.module.css';

const RECEIPT_QUERY = gql`
  query getResidentProfile {
    getResidentProfile {
      success
      error
      data {
        createdAt
      }
    }
  }
`;

type RECEIPT_QUERY_VARS = {
  registrationId: string;
};

type RECEIPT_QUERY_RES = {
  getResidentProfile: backendResponse<{
    createdAt: Date;
  }>;
};

// const doDownload = () => {
//   const container = document.getElementById('receipt-download-container');
//   if (container) {
//     html2canvas(container, {
//       allowTaint: true,
//       useCORS: true,
//     }).then(canvas => {
//       const link = document.createElement('a');
//       link.download = 'receipt.png';
//       canvas.toBlob(blob => {
//         if (!blob) return;
//         link.href = URL.createObjectURL(blob);
//         link.click();
//       });
//     });
//   }
// };

export default function RegistrationThankYouScreen(): ReactElement {
  const { registrationId } = useParams<{ registrationId: string }>();
  const [isLoading, setLoading] = useState(false);

  const { data } = useQuery<RECEIPT_QUERY_RES, RECEIPT_QUERY_VARS>(RECEIPT_QUERY, {
    variables: { registrationId },
    onCompleted: d => {
      if (d.getResidentProfile.success) {
        setLoading(false);
      }
    },
    onError: d => {
      setLoading(false);
    },
  });

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className={style.container}>
      {isLoading ? (
        <>
          <div className={style.modal_backdrop}></div>
          <LoaderCircle />
        </>
      ) : (
        <div className={`${style.box} white `}>
          <RegistrationProcessNav
            stepNum={5}
            title={''}
            subtitle=""
          />
          <div className={style.flexBox}>
            <div className={style.boxLeft}>
              <div className={style.infoBox}>
                <h3 className={`${style.title} textColorSuperDark `}>
                  Registration Summary
                </h3>
                <table className={style.tableBox}>
                  <tr>
                    <th>Registration Number:</th>
                    <td>
                      <ul>
                        <li className={style.passNum}>{registrationId}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>Date:</th>
                    {data?.getResidentProfile?.data?.createdAt ? (
                      <td>
                        <ul>
                          <li className={style.liStyle}>
                            {formatDate(
                              new Date(data.getResidentProfile?.data?.createdAt),
                            )}
                          </li>
                        </ul>
                      </td>
                    ) : (
                      <td>NA</td>
                    )}
                  </tr>
                </table>
                {/* <div className={style.btnPrint}>
                  <GenericButton
                    title="Print Registration"
                    clickHandler={() => doDownload()}
                    outline="large"
                  />
                </div>
                <div className={style.btnPrint}>
                  <Link
                    to={{
                      pathname: `/resident/registration/${registrationId}/receipt/download`,
                      state: {
                        // registrationData: data?.getResidentProfile?.data,
                      },
                    }}
                  >
                    <GenericButton
                      outline="large"
                      title="Download Registration Info"
                      color="blue"
                    />
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
          <div className={style.btn}>
            <Link to="/">
              <GenericButton size="large" color="blue" title="Continue" />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

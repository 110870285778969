import { gql } from '@apollo/client';
import { backendResponse } from '../types/backendResponse';

const ALL_COMMUNITY_FEATURES_FRAGMENMT = gql`
  fragment AllCommunityFeatures on CommunityConfig {
    passFeatures
    gateCode
    host {
      enabled
      validLength
      ownerPin
      rentalRegistrationLabel
      inviteGuest {
        enabled
        hostMustPay
        multiplePasses
      }
      initialRegistration {
        educationStepEnabled
        documentStepEnabled
        rentalRegistrationStepEnabled
      }
    }
    resident {
      enabled
      registration {
        educationStepEnabled
        documentStepEnabled
        ownerPinEnabled
      }
      residentPasses {
        enabled
      }
      inviteVendor {
        enabled
      }
      familyAndFriendsPasses {
        enabled
        identificationRequired
      }
    }
    isOfficerInsightsEnabled
    legacySingleUsePassEnabled
    protectGuestDoubleCharge
    vendor {
      enabled
      businessTypeRequired
      hideEmployeeMenu
      hideVehicleMenu
      initialRegistration {
        educationByBusinessType
        autoApprove
      }
    }
  }
`;
export type CommuntiyConfigType = {
  passFeatures?: boolean;
  gateCode?: boolean;
  isOfficerInsightsEnabled?: boolean;
  protectGuestDoubleCharge?: boolean;
  legacySingleUsePassEnabled?: boolean;
  host?: {
    enabled?: boolean;
    validLength?: number;
    ownerPin?: string;
    rentalRegistrationLabel?: string;
    inviteGuest?: {
      enabled?: boolean;
      hostMustPay?: boolean;
      multiplePasses?: boolean;
    };
    initialRegistration?: {
      educationStepEnabled?: boolean;
      documentStepEnabled?: boolean;
      rentalRegistrationStepEnabled?: boolean;
    };
  };
  resident?: {
    enabled?: boolean;
    registration?: {
      educationStepEnabled?: boolean;
      documentStepEnabled?: boolean;
      ownerPinEnabled?: boolean;
    };
    residentPasses?: {
      enabled?: boolean;
    };
    inviteVendor?: {
      enabled?: boolean;
    };
    familyAndFriendsPasses?: {
      enabled?: boolean;
      identificationRequired?: boolean;
    };
  };
  vendor?: {
    enabled?: boolean;
    businessTypeRequired?: boolean;
    hideEmployeeMenu?: boolean;
    hideVehicleMenu?: boolean;
    initialRegistration?: {
      educationByBusinessType?: boolean;
      autoApprove?: boolean;
    };
  };
};

export const GET_ALL_COMMUNITIES = gql`
  ${ALL_COMMUNITY_FEATURES_FRAGMENMT}
  query GetAllCommunities {
    getAllCommunities {
      error
      success
      data {
        communityId
        name
      }
    }
  }
`;
export type GET_ALL_COMMUNITIES_VARS = Record<string, unknown>;
export type GET_ALL_COMMUNITIES_RES = {
  getAllCommunities: backendResponse<
    {
      communityId: string;
      name: string;
    }[]
  >;
};

export const GET_COMMUNITY_DATA = gql`
  ${ALL_COMMUNITY_FEATURES_FRAGMENMT}
  query GetCommunityById($communityId: String!) {
    getCommunityById(communityId: $communityId) {
      success
      error
      data {
        styling {
            mainColor
            secondaryColor
            thirdColor
            hoverColor
            gradient
            gradientStart
            gradientEnd
            headerImage
            headerColor
            headerLogoHeight
            backgroundImage
            backgroundSize
            backgroundPosition
            backgroundImageWidth
            backgroundImageHeight
            superDarkGrey
            darkGrey
            mediumGrey
            lightGrey
            specialGrey
            sucessColor
            alertColor
            backgroundColor
            green
            red
            mode
          }
        communityId
        name
        stripeFeeAdditional
        featuresConfig {
          ...AllCommunityFeatures
        }
        zendeskURLs {
          guest
          vendor
          resident
          host
          admin
        }
        zendeskWidgetSrc {
          guest
          vendor
          resident
          host
          admin
        }
      }
    }
  }
`;
export type GET_COMMUNITY_DATA_VARS = {
  communityId: string;
  __cfg_no_token?: boolean;
};

type stylingConfig = {
  mainColor: string;
  secondaryColor: string;
  thirdColor: string;
  hoverColor: string;
  gradient: string;
  gradientStart: string;
  gradientEnd: string;
  headerImage: string;
  headerColor: string;
  headerLogoHeight: string;
  backgroundImage: string;
  backgroundSize: string;
  backgroundPosition: string;
  backgroundImageWidth: string;
  backgroundImageHeight: string;
  superDarkGrey: string;
  darkGrey: string;
  mediumGrey: string;
  lightGrey: string;
  specialGrey: string;
  sucessColor: string;
  alertColor: string;
  backgroundColor: string;
  green: string;
  red: string;
  mode: string;
};

export type CommunityType = {
  styling?: stylingConfig;
  communityId: string;
  name: string;
  stripeFeeAdditional?: boolean;
  featuresConfig?: CommuntiyConfigType;
  zendeskURLs?: {
    guest: string | null;
    vendor: string | null;
    resident: string | null;
    host: string | null;
    admin: string | null;
  };
  zendeskWidgetSrc?: {
    guest: string | null;
    vendor: string | null;
    resident: string | null;
    host: string | null;
    admin: string | null;
  };
  hostDocumentUploadConfig?: {
    specialInstructions: [] | null;
    instructionText: string | null;
  };
};

export type GET_COMMUNITY_DATA_RES = {
  getCommunityById: backendResponse<CommunityType[]>;
};

import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import style from './card.module.css';

interface Props {
  img: any;
  alt?: string;
  title: string;
  description?: string;
  buttonLabel: string;
  href: string;
  hidden?: boolean;
  subDescription?: string[];
  cardColorClass?: string;
}

export const Card = (props: Props): ReactElement => {
  const {
    img,
    title,
    description,
    buttonLabel,
    href,
    hidden,
    subDescription,
    cardColorClass,
  } = props;

  if (hidden) return <></>;
  return (
    <Link to={href}>
      <div className={style.cardBox}>
        <div className={`${style.card} ${cardColorClass || 'white'} `}>
          <div
            className={`${style.image} ${
              cardColorClass ? 'textColorWhite' : 'textColorDark'
            }`}
          >
            {img}
          </div>
          <h5
            className={`${style.card__title} ${
              cardColorClass ? 'textColorMain' : 'textColorDark'
            } `}
          >
            {title}
          </h5>
          {description && (
            <p
              className={`${style.card__description} ${
                subDescription?.length ? style.desc_left : style.desc_left_center
              } ${cardColorClass ? 'textColorMain' : 'textColorDark'}  `}
            >
              {description}
            </p>
          )}
          {Boolean(subDescription?.length) && (
            <ul className={style.card__sub_list}>
              {subDescription?.map((item, index) => (
                item && (
                  <li
                    key={index}
                    className={`${style.card__sub_description} ${
                      cardColorClass ? 'textColorMain' : 'textColorDark'
                    } `}
                  >
                    {item}
                  </li>
                )
              ))}
            </ul>
          )}
          <div className={style.card__btn}>
            <div className={style.card__btnInnerBox}>
              <GenericButton color="blue" size="large" title={buttonLabel} />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
Card.defaultProps = {
  alt: 'card img',
  hidden: false,
};
export default Card;

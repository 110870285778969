import { gql, useQuery } from '@apollo/client';

export const GET_USER_INFO = gql`
  query GetUserInfo {
    getUserInfo {
      success
      error
      data {
        address
        city
        country
        communityCode
        email
        firstName
        lastName
        pdkId
        phoneNumber
        roles
        state
        status
        userId
        zipCode
      }
    }
  }
`;

const useGetUserInfo = (
  onCompleted?: (data: any) => void,
  onError?: (error: any) => void,
): { userInfo: any; refetch: any } => {
  const { data: userInfo, refetch } = useQuery(GET_USER_INFO, {
    variables: {},
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError,
    onCompleted,
  });
  return { userInfo: userInfo?.getUserInfo.data, refetch };
};

export default useGetUserInfo;

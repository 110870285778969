import React, {
  ChangeEvent,
  Fragment,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import alertIcon from '../../../common_lib_front/assets/alert.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import SearchSelector from '../../../common_lib_front/components/searchSelector/searchSelector';
import { ADDRESS_VALIDATION } from '../../../common_lib_front/hooks/useAddressValidation';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import RegistrationProcessNav from '../../../components/registrationProcessNav/registrationProcessNav';
import style from './residentInformation.module.css';
import useRegistrationInformation from './useRegistrationInformation';

export default function RegistrationInformation(): ReactElement {
  const history = useHistory();
  const { communityId, featuresConfig } = useContext(CommunityContext);
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  // const isRegComplete = getUserRegistrationSteps();
  const {
    registrationId,
    registrationData,
    submitHandler,
    editHelper,
    registrationError,
    fetchAddressValidation,
    searchSelectorHandler,
    addProperty,
    deleteProperty,
    // searchHandlerForMailingHandler,
  } = useRegistrationInformation();

  const [multiAddresses, setMultiAddressMode] = useState<boolean>(false);

  useEffect(() => {
    if (registrationData.communityAddr.length > 1) {
      setMultiAddressMode(true);
    }
  }, [registrationData.communityAddr]);

  const [newAddressData, setNewAddressData] = useState<Array<any>>([]);

  const [aditionalAddress, setAditionalAddress] = useState('');

  const deletePropertyCall = (propertyId: string) => {
    if (registrationData?.communityAddr?.length > 0) {
      registrationData.communityAddr = registrationData.communityAddr.filter(ele => {
        return ele.propertyId !== propertyId;
      });
      editHelper({
        communityAddr: registrationData.communityAddr,
      });
      if (propertyId) {
        deleteProperty(propertyId);
      }
    }
  };

  // if (isRegComplete) {
  //   console.log(isRegComplete);
  //   // return <Redirect to={'/'} />;
  // }

  // const { communityId } = useContext(CommunityContext);

  let nextPageURL = `/resident/registration/${registrationId}/education`;
  if (!featuresConfig?.resident?.registration?.educationStepEnabled) {
    nextPageURL = `/resident/registration/${registrationId}/documents`;
    if (!featuresConfig?.resident?.registration?.documentStepEnabled) {
      nextPageURL = `/resident/registration/${registrationId}/secure-checkout`;
    }
  }

  const submitDataHandler = async () => {
    // validation
    if (
      (registrationData.phoneNumber && registrationData.phoneNumber.length < 5) ||
      (registrationData.emergencyPhoneNumber &&
        registrationData.emergencyPhoneNumber.length < 5)
    ) {
      setAlert('Please fill out the required info');
      setAlertColor('red');
      return;
    }
    // submit data and redirect
    try {
      await submitHandler();
      history.replace(nextPageURL);
    } catch (err) {
      // handle errors
      if (typeof err === 'string') {
        setAlert(err);
        setAlertColor('red');
      } else {
        setAlert('Something went wrong. Your changes may not be saved.');
        setAlertColor('red');
      }
    }
  };

  const mailingDataHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      registrationData.mailingCity = registrationData.city;
      registrationData.mailingState = registrationData.state;
      registrationData.mailingStreet = registrationData.street;
      registrationData.mailingUnit = registrationData.unit;
      registrationData.mailingZipCode = registrationData.zipCode;
    } else {
      registrationData.mailingCity = '';
      registrationData.mailingState = '';
      registrationData.mailingStreet = '';
      registrationData.mailingUnit = '';
      registrationData.mailingZipCode = '';
    }
    editHelper({ mailAddSameasCommunityAdd: event.target.checked });
  };

  const multiAddressHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setMultiAddressMode(true);
    } else {
      setMultiAddressMode(false);
    }
  };

  return (
    <div className={style.container}>
      {/* {!isRegComplete ? ( */}
      <div className={`${style.box} white `}>
        <RegistrationProcessNav
          title={'Step 1: Resident Information'}
          subtitle={'Please provide the required information below'}
          stepNum={1}
        />
        <div className={style.alert}>
          <div className={style.alertBox}>
            <GenericAlert color={alertColor} title={alert} hidden={!alert} />
          </div>
        </div>
        <form
          className={style.form}
          onSubmit={async (e: React.FormEvent) => {
            e.preventDefault();
            await submitDataHandler();
          }}
        >
          <div className={style.inputBox}>
            <span className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="resident-firstname-inpt"
                labelTitle="First Name"
                inputValue={registrationData.firstName || ''}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ firstName: e.target.value });
                }}
                required
                autoComplete={false}
              />
            </span>
            <span className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="resident-lastname-inpt"
                labelTitle="Last Name"
                inputValue={registrationData.lastName || ''}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ lastName: e.target.value });
                }}
                required
                autoComplete={false}
              />
            </span>
          </div>
          <div className={style.inputBox}>
            <span className={style.inputLong}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="resident-address-inpt"
                labelTitle="Email"
                inputValue={registrationData.email || ''}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ email: e.target.value });
                }}
                required
                autoComplete={false}
              />
            </span>
          </div>
          <div className={style.inputBox}>
            <span className={style.inputLong}>
              <PhoneInputWrapper
                standardInput
                inputTitle="Phone Number (Primary)"
                value={registrationData.phoneNumber || ''}
                changeHandler={val => {
                  editHelper({ phoneNumber: val });
                }}
                required
              />
            </span>
          </div>
          <div className={style.inputBox}>
            <span className={style.inputLong}>
              <PhoneInputWrapper
                standardInput
                inputTitle="Phone Number (Alternate)"
                value={registrationData.altPhoneNum || ''}
                changeHandler={val => {
                  editHelper({ altPhoneNum: val });
                }}
                required
              />
            </span>
          </div>
          <div className={style.label}>
            <h3>Community Address</h3>
          </div>

          <div className={style.inputBox}>
            <span className={style.inputLong}>
              {fetchAddressValidation ? (
                <div className={`${style.searchSelector}`}>
                  <span className={`${style.inputLong}`}>
                    <SearchSelector
                      htmlFor={'Street'}
                      title={'Unit/Street Address'}
                      value={registrationData.street || ''}
                      changeHandler={(val: string) => {
                        editHelper({ street: val });
                      }}
                      fetch={fetchAddressValidation}
                      selectHandler={searchSelectorHandler}
                      required={true}
                    />
                  </span>
                </div>
              ) : (
                <InputField
                  closedInput
                  htmlFor={'Unit/Street Address'}
                  labelTitle="Street"
                  inputValue={registrationData.street || ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    editHelper({ street: e.target.value });
                  }}
                  required
                  autoComplete={false}
                />
              )}
            </span>
          </div>

          {/* <div className={style.inputBox}>
            <span className={style.inputLong}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="resident-street-inpt"
                labelTitle="Street"
                inputValue={registrationData.street}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ street: e.target.value });
                }}
                required
              />
            </span>
          </div> */}

          <div className={style.inputBox}>
            <span className={style.inputLong}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="resident-unit-inpt"
                labelTitle="Apt/Unit"
                inputValue={registrationData.unit || ''}
                disabled={Boolean(fetchAddressValidation)}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ unit: e.target.value });
                }}
                autoComplete={false}
              />
            </span>
          </div>
          <div className={style.inputBox}>
            <span className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="resident-city-inpt"
                labelTitle="City"
                inputValue={registrationData.city || ''}
                disabled={Boolean(fetchAddressValidation)}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ city: e.target.value });
                }}
                required
                autoComplete={false}
              />
            </span>
            <span className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="resident-state-inpt"
                labelTitle="State"
                inputValue={registrationData.state || ''}
                disabled={Boolean(fetchAddressValidation)}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ state: e.target.value });
                }}
                required
                autoComplete={false}
              />
            </span>
          </div>
          <div className={style.inputBox}>
            <span className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="resident-zip-inpt"
                formatCode="zip-strict"
                labelTitle="Zip Code"
                inputValue={registrationData.zipCode || ''}
                disabled={Boolean(fetchAddressValidation)}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ zipCode: e.target.value });
                }}
                required
                minLength={5}
                autoComplete={false}
              />
            </span>
          </div>
          <div className={style.inline_label}>
            <label>
              Please check if you own multiple properties within the community and add
              each property
            </label>
            <InputField
              htmlFor="resident-multipleProperties-input"
              inputType="checkbox"
              checked={!!multiAddresses || false}
              changeHandler={multiAddressHandler}
              autoComplete={false}
            />
          </div>
          {multiAddresses ? (
            <div className={style.inputBox}>
              <span className={style.inputLong}>
                <div className={`${style.searchSelector}`}>
                  <span className={`${style.inputLong}`}>
                    <SearchSelector
                      htmlFor={'street-add-new'}
                      title={'Street'}
                      value={aditionalAddress || ''}
                      changeHandler={(val: string) => {
                        setAditionalAddress(val);
                      }}
                      fetch={(address: string) => {
                        return backendClient
                          .query({
                            query: ADDRESS_VALIDATION,
                            variables: {
                              address: address,
                            },
                          })
                          .then((d: any) => {
                            setNewAddressData(d.data.addressValidation.data);
                            return d.data.addressValidation.data.map(
                              (a: { address: string }) => a.address,
                            );
                          });
                      }}
                      selectHandler={d => {
                        const filtered = newAddressData.filter(
                          address => address.address === d,
                        );
                        if (filtered.length) {
                          const insert = {
                            street: filtered[0]?.address,
                            unit: '',
                            city: filtered[0]?.city,
                            state: filtered[0]?.state,
                            zipCode: filtered[0]?.zip,
                          };
                          addProperty?.(insert);
                          setAditionalAddress('');
                        }
                      }}
                    />
                  </span>
                </div>
              </span>
            </div>
          ) : null}
          {/* {console.log('address', registrationData.communityAddr)} */}
          {multiAddresses && registrationData.communityAddr.length > 0 ? (
            <>
              {registrationData.communityAddr
                .filter(el => el.street != registrationData.street)
                .map((address: any, key) => {
                  return (
                    <div className={style.inputBox} key={key}>
                      <span className={style.inputLong}>
                        <InputField
                          closedInput
                          htmlFor={`add-street-${key + 2}`}
                          labelTitle={`Property #${key + 2}`}
                          inputValue={address.street || ''}
                          disabled={true}
                          autoComplete={false}
                        />
                        <a
                          href="javascript:void(0);"
                          onClick={() => deletePropertyCall(address.propertyId)}
                        >
                          delete
                        </a>
                      </span>
                    </div>
                  );
                })}
            </>
          ) : null}
          <div className={style.inline_label}>
            <label>
              Check if mailing is same as community address. If registering multiple
              addresses the first will be used
            </label>
            <InputField
              htmlFor="resident-mailAddSameasCommunityAdd-input"
              inputType="checkbox"
              checked={registrationData.mailAddSameasCommunityAdd || false}
              changeHandler={mailingDataHandler}
              autoComplete={false}
            />
          </div>

          {!registrationData.mailAddSameasCommunityAdd ? (
            <Fragment>
              <div className={style.label}>
                <h3>Mailing Address</h3>
              </div>

              <div className={style.label}>
                <span className={style.inputLong}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="resident-mailing-street-inpt"
                    labelTitle="Street"
                    inputValue={registrationData.mailingStreet || ''}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      editHelper({ mailingStreet: e.target.value });
                    }}
                    required
                    disabled={registrationData.mailAddSameasCommunityAdd || false}
                    autoComplete={false}
                  />
                </span>
              </div>

              {/* <div className={style.inputBox}>
                <span className={style.inputLong}>
                  {fetchAddressValidation ? (
                    <div className={style.searchSelector}>
                      <span className={`${style.inputLong}`}>
                        <SearchSelector
                          htmlFor={'mailing-Street'}
                          title={'Street'}
                          value={registrationData.mailingStreet}
                          changeHandler={(val: string) => {
                            editHelper({ mailingStreet: val });
                          }}
                          fetch={fetchAddressValidation}
                          selectHandler={searchHandlerForMailingHandler}
                          required={true}
                        />
                      </span>
                    </div>
                  ) : (
                    <InputField
                      closedInput
                      htmlFor={'mailing-Street'}
                      labelTitle="mailing-street"
                      inputValue={registrationData.mailingStreet}
                      changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                        editHelper({ mailingStreet: e.target.value });
                      }}
                      required
                    />
                  )}
                </span>
              </div> */}

              <div className={style.inputBox}>
                <span className={style.inputLong}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="resident-mailing-unit-inpt"
                    labelTitle="Apt/Unit"
                    inputValue={registrationData.mailingUnit || ''}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      editHelper({ mailingUnit: e.target.value });
                    }}
                    disabled={registrationData.mailAddSameasCommunityAdd || false}
                    autoComplete={false}
                  />
                </span>
              </div>
              <div className={style.inputBox}>
                <span className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="resident--mailing-city-inpt"
                    labelTitle="City"
                    inputValue={registrationData.mailingCity || ''}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      editHelper({ mailingCity: e.target.value });
                    }}
                    required
                    disabled={registrationData.mailAddSameasCommunityAdd || false}
                    autoComplete={false}
                  />
                </span>
                <span className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="resident--mailing-state-inpt"
                    labelTitle="State"
                    inputValue={registrationData.mailingState || ''}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      editHelper({ mailingState: e.target.value });
                    }}
                    required
                    disabled={registrationData.mailAddSameasCommunityAdd || false}
                    autoComplete={false}
                  />
                </span>
              </div>
              <div className={style.inputBox}>
                <span className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="resident--mailing-zip-inpt"
                    formatCode="zip-strict"
                    labelTitle="Zip Code"
                    inputValue={registrationData.mailingZipCode || ''}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      editHelper({ mailingZipCode: e.target.value });
                    }}
                    required
                    minLength={5}
                    disabled={registrationData.mailAddSameasCommunityAdd || false}
                    autoComplete={false}
                  />
                </span>
              </div>
            </Fragment>
          ) : (
            <></>
          )}

          <div className={style.label}>
            <h3>Emergency Contact</h3>
          </div>
          <div className={style.inputBox}>
            <span className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="resident-emergency-firstname-inpt"
                labelTitle="First Name"
                inputValue={registrationData.emergencyFirstName || ''}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ emergencyFirstName: e.target.value });
                }}
                required
                autoComplete={false}
              />
            </span>
            <span className={style.inputShort}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="resident-emergency-lastname-inpt"
                labelTitle="Last Name"
                inputValue={registrationData.emergencyLastName || ''}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ emergencyLastName: e.target.value });
                }}
                required
                autoComplete={false}
              />
            </span>
          </div>
          <div className={style.inputBox}>
            <span className={style.inputLong}>
              <InputField
                largeSize
                highlightOnFocus
                htmlFor="resident-emergency-address-inpt"
                labelTitle="Email"
                inputValue={registrationData.emergencyEmail || ''}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  editHelper({ emergencyEmail: e.target.value });
                }}
                required
                autoComplete={false}
              />
            </span>
          </div>
          <div className={style.inputBox}>
            <span className={style.inputLong}>
              <PhoneInputWrapper
                standardInput
                inputTitle="Phone Number (Mobile)"
                value={registrationData.emergencyPhoneNumber || ''}
                changeHandler={val => {
                  editHelper({ emergencyPhoneNumber: val });
                }}
                required
              />
            </span>
          </div>
          <div className={style.inputBox}></div>
          {
            featuresConfig?.resident?.registration?.ownerPinEnabled && (
              <div className={style.inputBox}>
                <span className={style.inputLong}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="resident-emergency-pin-inpt"
                    labelTitle="Owner Verification Password / Pin"
                    inputValue={registrationData.ownerPin || ''}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      editHelper({ ownerPin: e.target.value });
                    }}
                    autoComplete={false}
                  />
                  {/* <div className={style.ownerPinText}>
                    Owner Pin (This is the number you use to request guest passes)
                  </div> */}
                </span>
              </div>
            )
          }
          <div className={style.alertBox}>
            <img src={alertIcon} alt="" />
            <p className={`${style.alert} textColorMedium `}>
              Please verify that the above information is current and accurate.
            </p>
          </div>
          <div className={style.btn}>
            <GenericButton size="large" color="blue" title="Continue" type="submit" />
          </div>
        </form>
      </div>
      {/* )
       :
       (
        <div>Loading....</div>
      )
      } */}
    </div>
  );
}

import React, { ReactElement, useEffect, useState } from 'react';
import { ReactComponent as AddImg } from '../../assets/add.svg';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import FamilyOrFriendPassInfo, {
  newFamilyOrFriendPass,
} from '../../common_lib_front/types/familyAndFriendsPassInfo';
import collectionTools from '../../common_lib_front/utilities/collectionTools';
import style from './familyAndFriendsPassCollection.module.css';
import FamilyAndFriendsPassForm from './familyAndFriendsPassForm';

interface Props {
  max?: number;
  min?: number;
  submitHandler: (data: Array<FamilyOrFriendPassInfo>) => void;
  disable?: boolean;
}

export const FamilyAndFriendsPassCollection = (props: Props): ReactElement => {
  const [data, setData] = useState<Array<FamilyOrFriendPassInfo>>([]);
  const { max, min, submitHandler, disable } = props;
  const { add, update, remove } = collectionTools({
    newElement: newFamilyOrFriendPass,
    data,
    setData,
  });

  useEffect(() => {
    if (min !== undefined) {
      for (let i = data.length; i < min; i += 1) {
        add();
      }
    }
  }, [add, data.length, min]);

  return (
    <div className={style.container}>
      {data.map((elem: FamilyOrFriendPassInfo, idx: number) => (
        <FamilyAndFriendsPassForm
          allowRemove={min !== undefined ? data.length > min : true}
          familyAndFriendsPassData={elem}
          idx={idx}
          key={idx}
          parentClass={data.length === 1 ? 'mxAuto' : ''}
          remove={() => remove(idx)}
          update={(obj: any) => update(idx, obj)}
        />
      ))}
      <div className={style.card}>
        <div className={style.btn}>
          <GenericButton
            clickHandler={() => submitHandler(data)}
            color="yellow"
            size="large"
            title="share"
            disabled={disable}
          />
        </div>
        {max !== undefined && data.length < max ? (
          <div className={style.addBtn}>
            <GenericButton
              icon={(className: string) => <AddImg className={`${className}`} />}
              title=""
              clickHandler={() => {
                if (max !== undefined && data.length < max) add();
              }}
            />
            <p className={`${style.textBelow} textColorSecondary `}>Add Recipient</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

FamilyAndFriendsPassCollection.defaultProps = {
  max: undefined,
  min: 1,
};

import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { ReactComponent as GetPasses } from '../../../assets/getPasses.svg';
import { ReactComponent as HostRegistrationPic } from '../../../assets/hostRegistrationStandout.svg';
// import { ReactComponent as MyPass } from '../../../assets/myPass.svg';
import { ReactComponent as OwnerCodePic } from '../../../assets/ownerCode.svg';
import { ReactComponent as ProfilePic } from '../../../assets/profile.svg';
import { ReactComponent as ResidentRegistrationPic } from '../../../assets/residentRegistration.svg';
import { ReactComponent as SharePass } from '../../../assets/sharePass.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import Card from '../../../components/card/card';
import { useGetResidentApplication } from '../../../hooks/useGetApplication';
import style from './residentHome.module.css';

export type cardConfigType = {
  id: number;
  img: React.ReactElement;
  alt: string;
  title: string;
  description?: string;
  subDescription?: string[];
  buttonLabel: string;
  href: string;
  hidden: boolean;
  cardColorClass?: string;
};

export default function ResidentHome(): ReactElement {
  const { communityId, featuresConfig } = useContext(CommunityContext);
  const [residentIsNotApproved, setResidentIsNotApproved] = useState<boolean>(true); // by default set to hide as true

  const { data: applicationData } = useGetResidentApplication({
    onCompleted: () => {
      // I honestly don't know the right way to get rid of this redirect but I'm
      // sick of it being here
      if (process.env.REACT_APP_DISABLE_INITIAL_REGISTRATION === 'true') return;
      // imediately redirect to initial registration process if not complete
      // console.log(d?.getApplication.data?.status, userDetails);
      // if (['incomplete', undefined].includes(d?.getApplication.data?.status)) {
      //   if (userDetails?.userId) {
      //     history.replace(`/resident/registration/${userDetails?.userId}`);
      //   } else {
      //     history.replace('/resident/registration');
      //   }
      // }
    },
  });

  useEffect(() => {
    if (applicationData?.getApplication?.data) {
      if (['approved'].indexOf(applicationData.getApplication.data.status) < 0) {
        setResidentIsNotApproved(true); // set hidden as true when application status of user is not approved.
      } else {
        setResidentIsNotApproved(false); // set hidden as false when application status of user is approved.
      }
    }
  }, [applicationData]);

  // let displayNone;
  // if (!getAssets().config.host) {
  //   displayNone = style.displayNone;
  // }

  const cardList: cardConfigType[] = [
    {
      id: 1,
      img: <ProfilePic />,
      alt: 'my profile img',
      title: 'My Profile',
      subDescription: ['Family and Friends (Address Book)', 'Vehicles'],
      buttonLabel: 'Edit Info',
      href: '/resident/edit-profile/my-profile',
      hidden:
        featuresConfig?.resident?.enabled === undefined ||
        featuresConfig?.resident?.enabled
          ? false
          : true,
    },
    {
      id: 2,
      img: <GetPasses />,
      alt: 'get a pass img',
      title: 'Get a Pass',
      description: '',
      subDescription: [
        featuresConfig?.resident?.residentPasses?.enabled ? 'Resident Passes' : '',
        featuresConfig?.resident?.familyAndFriendsPasses?.enabled ? 'Family and Friends (Visitor Passes)' : '',
        featuresConfig?.resident?.inviteVendor?.enabled ?  'Vendor Passes' : '',
      ],
      buttonLabel: 'Get Passes',
      href: '/resident/get-passes',
      hidden:
        residentIsNotApproved || // hide if resident is not approved
        !(
          featuresConfig?.resident?.residentPasses?.enabled ||
          featuresConfig?.resident?.familyAndFriendsPasses?.enabled ||
          featuresConfig?.resident?.inviteVendor?.enabled
        ), // hide if no options within are enabled
    },
    {
      id: 3,
      img: <SharePass />,
      alt: 'my passes img',
      title: 'My Passes',
      description: 'Manage Your Passes',
      subDescription: ['Replacements', 'Share a Pass', 'Download/Print'],
      buttonLabel: 'Manage Passes',
      href: '/resident/my-passes',
      hidden:
        residentIsNotApproved || // hide if resident is not approved
        !(
          featuresConfig?.resident?.residentPasses?.enabled ||
          featuresConfig?.resident?.familyAndFriendsPasses?.enabled ||
          featuresConfig?.resident?.inviteVendor?.enabled
        ), // hide if no options within are enabled
    },
    {
      id: 4,
      img: <SharePass />,
      alt: 'share pass img',
      title:
        communityId === 'grandcomplex' ? 'Share a One-Time Vendor Pass' : 'Share a Pass',
      description: 'You can share a single-use pass here.',
      buttonLabel: 'Share a Pass',
      href: '/resident/share-pass',
      ////////////////////////////////////////////////////////////////
      //                                                            //
      //      DO NOT CHANGE THIS UNLESS YOUR TICKET                 //
      //      EXPLICITLY REQUIRES YOU TO ENABLE THIS                //
      //      FOR A NEW COMMUNITY **OR** YOUR TICKET                //
      //      EXPLICITLY DIRECTS YOU TO CREATE A FEATURE            //
      //      FLAG FOR EXACTLY THIS. IF YOU HAVE THE                //
      //      SLIGHTEST DOUBT ABOUT A CHANGE YOU WANT               //
      //      TO MAKE HERE ***ASK SOMEONE ELSE FIRST***             //
      //                                                            //
      ////////////////////////////////////////////////////////////////
      hidden: communityId !== 'grandcomplex',
    },
    // {
    //   id: 5,
    //   img: <HostRegistrationPic />,
    //   alt: 'Host Portal img',
    //   title: 'Property Manager Portal',
    //   description: 'Invite a short-term rental guest here.',
    //   buttonLabel: 'Enter',
    //   href: '/host-portal',
    //   hidden: ['palmettodunes', 'sipoa'].indexOf(communityId) < 0,
    // },
    {
      id: 6,
      img: <HostRegistrationPic />,
      alt: 'Host Portal img',
      title: 'Host Registration Portal',
      description: '',
      subDescription: [
        'Register as a rental host',
        'Register a Rental Property',
        'Invite a Guest ',
        'Manage Guest and Rentals',
      ],
      buttonLabel: 'Enter',
      href: '/host-portal',
      hidden: !featuresConfig?.host?.enabled,
      // ['sipoa'].indexOf(communityId) > -1 && userDetails?.profileStatus === 'approved'
      //   ? false
      //   : true,
      cardColorClass: style.cardOutstand,
    },
    {
      id: 7,
      img: <OwnerCodePic />,
      alt: 'owner gate code img',
      title: 'Owner Gate Code',
      description: 'You can view and reset your owner gate code here.',
      buttonLabel: 'View Owner Code',
      href: '/resident/owner-code',
      hidden: featuresConfig?.gateCode && residentIsNotApproved ? false : true,
    },
    {
      id: 8,
      img: <ResidentRegistrationPic />,
      alt: 'resident registration img',
      title: 'Resident Registration',
      description: 'Register as a resident.',
      buttonLabel: 'Enter',
      href: '/resident/registration',
      subDescription: [],
      hidden: !featuresConfig?.resident?.enabled && residentIsNotApproved,
    },
    // {
    //   id: 8,
    //   img: <RegistrationPic />,
    //   alt: 'host registration img',
    //   title: 'Short-Term Rental Registration',
    //   description: 'Register as a short-term rental host.',
    //   buttonLabel: 'Register Now',
    //   href: '/resident/host-registration',
    //   hidden:
    //     config.host &&
    //     ['palmettodunes'].indexOf(communityId) > -1 &&
    //     userDetails?.profileStatus === 'approved'
    //       ? false
    //       : true,
    // },
    // {
    //   id: 9,
    //   img: <HostRegistrationPic />,
    //   alt: 'host registration img',
    //   title: 'My Rentals',
    //   description: 'View and manage my short-term rentals.',
    //   buttonLabel: 'View Rentals',
    //   href: '/resident/my-rentals',
    //   hidden:
    //     config.host &&
    //     ['palmettodunes'].indexOf(communityId) > -1 &&
    //     userDetails?.profileStatus === 'approved'
    //       ? false
    //       : true,
    // },
  ];

  return (
    <div className={style.container}>
      <div className={style.box}>
        {cardList.map((ele, index: number) => {
          return !ele.hidden ? (
            <div className={style.box__innerBox} key={index}>
              <Card
                img={ele.img}
                alt={ele.alt}
                title={ele.title}
                description={ele.description}
                subDescription={ele.subDescription}
                buttonLabel={ele.buttonLabel}
                href={ele.href}
                cardColorClass={ele.cardColorClass || ''}
              />
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
}

import { ReactElement, useContext } from 'react';
import { ReactComponent as FamilyFriendsPic } from '../../../assets/familyFriends.svg';
import { ReactComponent as ResidentPic } from '../../../assets/residentPass.svg';
import { ReactComponent as VendorPassPic } from '../../../assets/vendorPass.svg';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import CustomCard from '../../../components/customCard/customCard';
import style from './getPasses.module.css';

export default function GetPasses(): ReactElement {
  const { name, featuresConfig } = useContext(CommunityContext);

  const cardList: any = [
    {
      id: 1,
      img: <ResidentPic />,
      alt: 'Resident Pass img',
      title: 'Resident Pass',
      description: '',
      buttonLabel: 'Get a Resident Pass',
      href: '/resident/get-passes/resident-pass',
      hidden: !featuresConfig?.resident?.residentPasses?.enabled,
    },
    {
      id: 2,
      img: <FamilyFriendsPic />,
      alt: 'Family and Friends Visitor Pass img',
      title: 'Family and Friends Visitor Pass',
      description: '',
      buttonLabel: 'Share a Pass',
      href: '/resident/get-passes/family-and-friends-pass',
      hidden: !featuresConfig?.resident?.familyAndFriendsPasses?.enabled,
    },
    {
      id: 3,
      img: <VendorPassPic />,
      alt: 'Vendor Pass Img',
      title: 'Vendor Pass',
      description: '',
      buttonLabel: 'Invite Vendor',
      href: '/resident/vendor-pass',
      hidden: !featuresConfig?.resident?.inviteVendor?.enabled,
    },
  ];

  return (
    <div className={style.container}>
      <div className={`${style.box} ${style.custom_card}`}>
        <div className={style.heading}>
          <h3>
            Welcome to <span> {name}</span>
          </h3>
        </div>
        {cardList.map((ele: any, index: number) => {
          return !ele.hidden ? (
            <div className={style.box__innerBox} key={index}>
              <CustomCard
                img={ele.img}
                alt={ele.alt}
                title={ele.title}
                buttonLabel={ele.buttonLabel}
                href={ele.href}
              />
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
}

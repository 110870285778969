import { gql, useQuery } from '@apollo/client';
import { useContext, useMemo } from 'react';
import { CommunityContext } from '../communityConfigs/communityContextProvider';
import { backendResponse } from '../types/backendResponse';

interface IUsePassInfoProps {
  passInfoId: string;
}

export interface IPassInfo {
  name: string;
  passInfoId: string;
  passApprovalRequire?: boolean;
  needsApplication?: boolean;
  priceInfo?: {
    allowCashOrCheck: boolean;
    addons?: {
      description: string;
      isFree: boolean;
      name: string;
      price: number;
    }[];
  };
}

export const GET_PASS_INFO = gql`
  query getPassInfo($passInfoId: String!) {
    getPassInfo(passInfoId: $passInfoId) {
      success
      error
      data {
        name
        passInfoId
        passApprovalRequire
        priceInfo {
          allowCashOrCheck
          addons {
            description
            isFree
            name
            price
          }
        }
      }
    }
  }
`;
type GET_PASS_INFO_VARS = {
  passInfoId: string;
};
type GET_PASS_INFO_RES = {
  getPassInfo: backendResponse<IPassInfo>;
};

export default function usePassInfo({ passInfoId }: IUsePassInfoProps): IPassInfo {
  const { data } = useQuery<GET_PASS_INFO_RES, GET_PASS_INFO_VARS>(GET_PASS_INFO, {
    variables: { passInfoId },
    fetchPolicy: 'cache-and-network',
  });

  return {
    ...data?.getPassInfo?.data,
  } as IPassInfo;
}

export const GET_PASS_INFOS_BY_COMMUNITY = gql`
  query GetPassInfosByCommunity($communityId: String!) {
    getPassInfosByCommunity(complete: true, communityId: $communityId) {
      success
      error
      data {
        name
        passInfoId
        passApprovalRequire
        priceInfo {
          allowCashOrCheck
          addons {
            description
            isFree
            name
            price
          }
        }
      }
    }
  }
`;
type GET_PASS_INFOS_BY_COMMUNITY_VARS = {
  communityId: string;
};
type GET_PASS_INFOS_BY_COMMUNITY_RES = {
  getPassInfosByCommunity: backendResponse<IPassInfo[]>;
};

export function useAllPassInfos(): Record<string, IPassInfo> {
  const { communityId } = useContext(CommunityContext);

  const { data } = useQuery<
    GET_PASS_INFOS_BY_COMMUNITY_RES,
    GET_PASS_INFOS_BY_COMMUNITY_VARS
  >(GET_PASS_INFOS_BY_COMMUNITY, {
    variables: {
      communityId,
    },
  });

  return useMemo(
    () =>
      data?.getPassInfosByCommunity.data?.reduce(
        (res: Record<string, IPassInfo>, val) => {
          res[val.passInfoId] = val;
          return res;
        },
        {},
      ) || {},
    [data],
  );
}
